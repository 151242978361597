import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import './Footer.css';
import mailService from "../../../services/api_services/mailService";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: ''
        };
        this.emailAddressChange = this.emailAddressChange.bind(this);
        this.addEmail = this.addEmail.bind(this);
    }

    componentDidMount() {
        mailService.getMailsCount((response) => {
            this.setState({noOfMails: response})
        });

    }

    emailAddressChange(event) {
        this.setState({emailAddress: event.target.value});
    }

    addEmail(event) {
        const data = {
            address: this.state.emailAddress
        };
        console.log(data);
        event.preventDefault();
        mailService.addMail(data);
    };

    render() {
        return (
            <footer id="footer" className="dark footer-bg">

                <div className="container">
                    <div className="footer-widgets-wrap clearfix">
                        <div className="col_full">
                            <div className="widget clearfix">

                                {/*<div className="line" style={{margin: '30px 0'}}/>*/}

                                <div className="col_half">
                                    <div className="widget clearfix">
                                        <div className="d-none d-lg-block">
                                            <div className="clear" style={{paddingTop: '2px'}}/>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 bottommargin-sm center">
                                                <div className="counter counter-small" style={{color: '#35BBAA'}}><span>56</span></div>
                                                <h5 className="nobottommargin">Total App Downloads</h5>
                                            </div>

                                            <div className="col-lg-6 bottommargin-sm center col_last">
                                                <div className="counter counter-small" style={{color: '#2CAACA'}}><span>{this.state.noOfMails || 'x'}</span></div>
                                                <h5 className="nobottommargin">Number of Mail addresses</h5>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div className="col_half col_last">
                                    <div className="widget subscribe-widget clearfix">
                                        <h5><strong>Subscription</strong> box:</h5>

                                        <form className="nobottommargin">
                                            <div className="input-group divcenter">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text"><i className="icon-email2"/></div>
                                                </div>
                                                <input type="email" name="emailAddress"
                                                       value={this.state.emailAddress} onChange={this.emailAddressChange}
                                                       className="form-control required email"
                                                       placeholder="Enter the Email"/>
                                                <div className="input-group-append">
                                                    <button onClick={this.addEmail} className="btn btn-success">Subscribe</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div id="copyrights">
                    <div className="container clearfix">
                        <div className="col_half">
                            <NavLink to="/">JCC Dashboard</NavLink> | © 2019, All Rights Reserved. <br/>
                            <div className="copyright-links">
                                Terms of Use | Privacy Policy
                            </div>
                        </div>
                        <div className="col_half col_last tright">
                            <div className="fright clearfix">
                                <a href="https://www.facebook.com/RCCGJCC" className="social-icon si-small si-borderless si-facebook">
                                    <i className="icon-facebook"/>
                                    <i className="icon-facebook"/>
                                </a>
                                <a href="https://twitter.com/JCCRCCGcoventry" className="social-icon si-small si-borderless si-twitter">
                                    <i className="icon-twitter"/>
                                    <i className="icon-twitter"/>
                                </a>
                                <a href="https://www.youtube.com/channel/UC1u4mTtRqrRCQt1caKRwkBA" className="social-icon si-small si-borderless si-youtube">
                                    <i className="icon-youtube"/>
                                    <i className="icon-youtube"/>
                                </a>
                                <a href="https://anchor.fm/pastorkolawole" className="social-icon si-small si-borderless si-podcast">
                                    <i className="icon-podcast"/>
                                    <i className="icon-podcast"/>
                                </a>
                            </div>
                            <div className="clear"/>
                            <i className="icon-envelope2"/> rccgjcccoventry@gmail.com <span className="middot">·</span>
                            <i className="icon-headphones"/> +44-78-7385-4225 <span className="middot"/>
                        </div>
                    </div>
                </div>
            </footer>
        );

    }

}

export default Footer;
