import React, {Component} from 'react';
import magicClass from "../../../../services/magic-class";

class createCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            name: '',
            description: ''
        };
        this.cancelCreate = this.cancelCreate.bind(this);
    }

    cancelCreate = event => {
        event.preventDefault();

        magicClass.switchDOM('LIST');
    };

    render(){
        return (
            <section id="content">
                <div className="content-wrap">
                    <div className="container clearfix">
                        <h3>Create Category</h3>
                        <form>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputEmail4">Email</label>
                                    <input type="email" className="form-control" id="inputEmail4"
                                           placeholder="Email"/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputPassword4">Password</label>
                                    <input type="password" className="form-control" id="inputPassword4"
                                           placeholder="Password"/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputAddress">Address</label>
                                <input type="text" className="form-control" id="inputAddress"
                                       placeholder="1234 Main St"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputAddress2">Address 2</label>
                                <input type="text" className="form-control" id="inputAddress2"
                                       placeholder="Apartment, studio, or floor"/>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputCity">City</label>
                                    <input type="text" className="form-control" id="inputCity"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputState">State</label>
                                    <select id="inputState" className="form-control">
                                        <option selected>Choose...</option>
                                        <option>...</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-2">
                                    <label htmlFor="inputZip">Zip</label>
                                    <input type="text" className="form-control" id="inputZip"/>
                                </div>
                            </div>

                            <button onClick={this.cancelCreate} className="btn btn-danger">Cancel</button>
                            <button type="submit" className="btn btn-primary">Sign in</button>
                        </form>
                    </div>
                </div>
            </section>
        );

    }

}

export default createCategory;
