import api_handler from "../api_handler";
import env from "../../environment";

const mailService = {

    getAllMails: (cb) => {
        const route = env.API_URL.prefix + env.API_URL.newsletter_service.getMails;
        api_handler.getAPi(route, (res) => {
            cb(res.data);
        }, (err) => {
            console.log('error ', err);

        })
    },

    addMail: (data) => {
        const route = env.API_URL.prefix + env.API_URL.newsletter_service.sendMail;
        api_handler.postApi(route, data, (res) => {
            console.log('New Email Address added', res); //ToDO add Notification Toast
        }, (err) => {
            console.log('New Email Address failed', err.data); //ToDO add Notification Toast

        })
    },

    getMailsCount: (cb) => {
        const route = env.API_URL.prefix + env.API_URL.newsletter_service.noOfMails;

        api_handler.getAPi(route, (res) => {
            const response = res.data;
            cb(response);
        }, (err) => {
            console.log('Email Address count fetch unsuccessful', err); //ToDO add Notification Toast

        })

    },

    toggleMailStatus: (index) => {
        const route = `${env.API_URL.prefix}${env.API_URL.newsletter_service.toggleMailStatus}/${index}`;
        api_handler.getAPi(route, res => {
            console.log('toggle successful', res); //ToDO Add Toast
        }, err => {
            console.log('toggle error', err); //ToDO Add Toast
        })

    }, //Todo Fix this service

};

export default mailService;
