import api_handler from "../api_handler";
import cacheService from "../cacheService";
import env from "../../environment";

const authService = {

    login: (data, callback) => {
        const route = env.API_URL.prefix + env.API_URL.auth.login;
        api_handler.postApi(route, data, res => {
            cacheService.set('token', res.token);
            callback(res);
        }, err => {
            //ToDo -- TOAST ERROR !
        });
    },

    logOut: () => {
        const route = env.API_URL.prefix + env.API_URL.auth.logout;
        api_handler.getAPi(route,  (res) =>  {
            cacheService.clear();
            return (window.location = '/');

        }, (err) => {
            cacheService.clear();
            return (window.location = '/'); //ToDo change window.reload to navigation method
        });
    }


};

export default authService;
