import api_handler from "../api_handler";
import env from "../../environment";

const categoryService = {

    getAllCategories: (cb) => {
        const route = env.API_URL.prefix + env.API_URL.category_service.category;
        api_handler.getAPi(route, (res) => {
            cb(res.data);
        }, (err) => {
            console.log('error ', err);

        })
    },

    toggleCategory: (index) => {
        const route = `${env.API_URL.prefix}${env.API_URL.category_service.toggleCategory}/${index}`;
        api_handler.getAPi(route, res => {
            console.log('toggle successful', res); //ToDO Add Toast
        }, err => {
            console.log('toggle error', err); //ToDO Add Toast
        })

    }, //Todo Fix this service

    deleteCategory: (index) => {
        const route = `${env.API_URL.prefix}${env.API_URL.category_service.category}/${index}`;
        api_handler.deleteApi(route, res => {
            console.log('deleted success', res); //TODO change to Toast
        }, err => {
            console.log('deleted error', err);

        })

    }


};

export default categoryService;
