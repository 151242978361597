import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import authService from "../../../services/api_services/authService";

import './Authentication.css';
import logo from '../../../assets/logo.png';

class Authentication extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: ''

        };

        this.usernameChange = this.usernameChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);

        this.loginUser = this.loginUser.bind(this);
    }

    usernameChange(event) {
        this.setState({username: event.target.value});
    }

    passwordChange(event) {
        this.setState({password: event.target.value});
    }


    loginUser(event) {
        const user = {
            username: this.state.username,
            password: this.state.password
        };
        event.preventDefault();
        authService.login(user, cb => {
            this.props.history.push('/dashboard');
        })
    };

    render() {
        return (
            <div>
                <section id="content">

                    <div className="content-wrap nopadding">
                        <div className="section nopadding nomargin edity"/>

                        <div className="section nobg full-screen nopadding nomargin">
                            <div className="container-fluid vertical-middle divcenter clearfix">

                                <div className="center">
                                    <img className="logo" src={logo} alt="JCC Logo"/>
                                </div>

                                <div className="card divcenter noradius noborder"
                                     style={{maxWidth: '400px', backgroundColor: 'rgba(255,255,255,0.93)'}}>
                                    <div className="card-body" style={{padding: '40px'}}>
                                        <form onSubmit={this.loginUser} id="login-form" className="nobottommargin">
                                            <h3 className="center">Login to the Dashboard</h3>

                                            <div className="col_full">
                                                <label htmlFor="username">Username:</label>
                                                <input type="text" id="username" name="username"
                                                       value={this.state.username} onChange={this.usernameChange}
                                                       className="form-control not-dark"/>
                                            </div>

                                            <div className="col_full">
                                                <label htmlFor="password">Password:</label>
                                                <input type="password" id="password" name="password"
                                                       value={this.state.password} onChange={this.passwordChange}
                                                       className="form-control not-dark"/>
                                            </div>

                                            <div className="col_full nobottommargin">
                                                <button className="button button-3d button-black nomargin" type="submit"
                                                        value="Submit">Login
                                                </button>
                                                <NavLink to="#" className="fright">Forgot Password?</NavLink>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="center dark"><small>Copyrights &copy; All Rights Reserved by RCCG
                                    JCC</small></div>

                            </div>
                        </div>

                    </div>

                </section>
            </div>
        );

    }

}

export default Authentication;
