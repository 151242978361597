import encryptService from "./encryptService";

const cacheService = {

    get: (key: string) => {
        return encryptService.decrypt(localStorage.getItem(key));
    },

    set: (key: string, data: any) => {
        const dataEncrypted = encryptService.encrypt(data);
        localStorage.setItem(key, dataEncrypted);
    },

    remove: (key: string) => {
        localStorage.removeItem(key);
    },

    clear: () => {
        localStorage.clear();
    }

};

export default cacheService;
