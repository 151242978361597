import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import Create from "./createCategory";
import Header from "../../../layouts/header/Header";
import Footer from "../../../layouts/footer/Footer";
import categoryService from "../../../../services/api_services/categoryService";
import Toast from "../../../../services/toast/toastRender";
import magicClass from "../../../../services/magic-class";

class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            name: '',
            description: '',
            modal: false,
            category: {}
        };
        this.addCategory = this.addCategory.bind(this);
        this.toggleStatus = this.toggleStatus.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.deleteCategory = this.deleteCategory.bind(this);
    }

    componentDidMount(): void {
        categoryService.getAllCategories((data) => {
            this.setState({categories: data});
        });
    }

    addCategory = event => {
        event.preventDefault();

        magicClass.switchDOM('CREATE');
        console.log('hello click')
    };

    toggleStatus = (event, index) => {
        categoryService.toggleCategory(index);
    };

    handleDelete = (event, category) => {
        console.log('cat', category);
        this.setState({modal: !this.state.modal, data: category});
        this.setState({category: category});

    };

    handleClose = () => {
        this.setState({modal: false})
    };

    deleteCategory = () => {
        const category = this.state.category;
        console.log('deleteCategory', category['_id']);
        categoryService.deleteCategory(category['_id']);
    };

    render() {
        let rendered;
        if (magicClass.pageContainerRender.createRender === true) {
            rendered = <Create/>
        } else {
            rendered = <section id="content">
                <div className="content-wrap">
                    <div className="container clearfix">
                        <h3>All Categories</h3>
                        <div className="widget widget-twitter-feed">
                            <div className="input-group mb-3">
                                <button style={{width: '25%'}} type="button" className="btn btn-info"
                                        onClick={this.addCategory}>New Category &nbsp; <i
                                    className="fa fa-object-group"/></button>
                            </div>
                        </div>


                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>

                            {this.state.categories.map((category, index) =>

                                <tr key={category['_id']}>
                                    <td>{index + 1}</td>
                                    <td>{category.name}</td>
                                    <td>{category.description}</td>
                                    <td>
                                        <Form.Check type="checkbox" id="custom-switch" label="Enable/Disable"
                                                    onChange={(e) => this.toggleStatus(e, category['_id'])}
                                                    defaultChecked={category.status}/>
                                    </td>
                                    <td>{category.status}</td>
                                    <td>
                                        <><i className="fa fa-2x fa-edit btn-outline-info"/></>
                                        <><i onClick={(e) => this.handleDelete(e, category)} className="fa fa-2x fa-trash btn-outline-danger"/></>
                                    </td>
                                </tr>
                            )}

                            </tbody>
                        </table>

                    </div>
                </div>
            </section>
        }
        return (
            <div>
                <Header/>
                <section id="page-title">
                    <div className="container clearfix">
                        <h1>Categories</h1>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><NavLink to="#">Home</NavLink></li>
                            <li className="breadcrumb-item active" aria-current="page">Category</li>
                        </ol>
                    </div>
                    <Toast/>
                </section>
                {rendered}
                <Footer/>
                <Modal show={this.state.modal}>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>Delete {this.state.category['name']} ? </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Woohoo, are you sure you want to delete this?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="success" onClick={this.deleteCategory}>
                            Yes, Delete Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }


}

export default Category;
