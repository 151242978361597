import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './Header.css';

import logo from '../../../assets/logo.png';
import authService from "../../../services/api_services/authService";
import userService from "../../../services/userService";

class Header extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeNav: 'current',
            dropdownOpen: false
        };
        this.logOut = this.logOut.bind(this);

    };

    logOut = () => {
        authService.logOut();
    };

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    };

    render(){
        let admin;
        if (userService.userType() === 'Admin') {
            admin = <li>
                <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggle()}>
                    <DropdownToggle caret className="postNav">
                        Settings<i className="icon-line-cog fa-spin"/>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem tag={NavLink} to="/settings/user">User Accounts</DropdownItem>
                        <DropdownItem tag={NavLink} to="/settings/category">Categories</DropdownItem>
                        <DropdownItem tag={NavLink} to="/settings/content">Contents</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem disabled>Audit</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>

            </li>
        }

        return (
            <header id="header" className="full-header">
                <div id="header-wrap">
                    <div className="container clearfix">

                        <div id="primary-menu-trigger"><i className="icon-reorder"/></div>
                        <div id="logo">
                            <a href="/" className="standard-logo"> <img src={logo} alt="JCC Logo"/></a>
                            <a href="/" className="retina-logo"><img src={logo} alt="JCC Logo"/></a>
                        </div>
                        <nav id="primary-menu">
                            <ul>
                                <li><NavLink to="/dashboard"><div>Home</div></NavLink>
                                </li>
                                <li><NavLink to="/quiz"><div>Quiz</div></NavLink>
                                </li>
                                <li><NavLink to="/newsletter"><div>Newsletter</div></NavLink>
                                </li>
                                {admin}

                            </ul>
                            <div id="top-cart">
                                <NavLink to="#" onClick={this.logOut} id="top-cart-trigger"><i className="fa fa-sign-out fa-2x"/></NavLink>
                            </div>
                        </nav>

                    </div>

                </div>

            </header>

        );

    }

}

export default Header;
