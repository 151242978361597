import CryptoJS from 'crypto-js';
import env from '../environment';

const encryptService = {

    encrypt: (data) => {
        if (!data) {
            return null;
        } else {
            return CryptoJS.AES.encrypt(JSON.stringify(data), env.KEY);
        }
    },

    decrypt: (data) => {
        if (!data) {
            return null;
        } else {
            const bytes = CryptoJS.AES.decrypt(data.toString(), env.KEY);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));


            // const bytes = CryptoJS.AES.decrypt(data.toString(), env.KEY);

        }
    },

    jwtDecode: (data) => {
        if (!data) {
            return null;
        } else if (typeof (data) === 'object') {
            return data;
        } else {
            const base64Url = data.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        }

    }

    // Add more for the decryption service
};

export default encryptService;
