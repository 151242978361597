import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import Header from "../../../layouts/header/Header";
import Footer from "../../../layouts/footer/Footer";
import contentService from "../../../../services/api_services/contentService";

class ContentConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contents: [],
            name: '',
            description: '',
            modal: false,
            content: {}
        };
        this.toggleStatus = this.toggleStatus.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.deleteContent = this.deleteContent.bind(this);
    }

    componentDidMount(): void {
        contentService.getAllContents((data) => {
            this.setState({contents: data});
            console.log('data', data);
        });
    }

    toggleStatus = (event, index) => {
        contentService.toggleContents(index);
    };

    handleDelete = (event, content) => {
        console.log('cat', content);
        this.setState({modal: !this.state.modal, data: content});
        this.setState({content: content});

    };

    handleClose = () => {
        this.setState({modal: false})
    };

    deleteContent = () => {
        const content = this.state.content;
        console.log('deleteContent', content['_id']);
        contentService.deleteContent(content['_id']);
        //TODO -- Alot of work to be done !!!
    };

    render() {

        // const categoryName = lodash.map(this.state.contents, (content, cat) => {
        //     return content.category_id.name;
        // });
        //
        // console.log(categoryName);

        const rendered = <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">

                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Category</th>
                            <th>Author</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>

                        {this.state.contents.map((content, index) =>


                            <tr key={content['_id']}>
                                <td>{index + 1}</td>
                                <td>{content['name']}</td>
                                <td>{content.description}</td>
                                <td>{content.category}</td>
                                <td>{content.author}</td>
                                <td>
                                    <Form.Check type="checkbox" id="custom-switch" label="Enable/Disable"
                                                onChange={(e) => this.toggleStatus(e, content['_id'])}
                                                defaultChecked={content.status}/>
                                </td>
                                <td>
                                    <><i className="fa fa-2x fa-edit btn-outline-info"/></>
                                    <><i onClick={(e) => this.handleDelete(e, content)} className="fa fa-2x fa-trash btn-outline-danger"/></>
                                </td>
                            </tr>
                        )}

                        </tbody>
                    </table>

                </div>
            </div>
        </section>;

        return (
            <div>
                <Header/>
                <section id="page-title">
                    <div className="container clearfix">
                        <h1>Contents Configuration</h1>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><NavLink to="#">Home</NavLink></li>
                            <li className="breadcrumb-item active" aria-current="page">Content Config</li>
                        </ol>
                    </div>
                </section>

                {rendered}

                <Footer/>

                <Modal show={this.state.modal}>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>Delete {this.state.content['name']} ? </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Woohoo, are you sure you want to delete this?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="success" onClick={this.deleteContent}>
                            Yes, Delete Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }


}

export default ContentConfig;
