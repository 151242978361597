import React, {Component} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import './App.css';
import Authentication from './components/pages/authentication/Authentication';
import Dashboard from "./components/pages/dashboard/Dashboard";
import Error from "./components/pages/error/Error";
import Newsletter from "./components/pages/newsletter/Newsletter";
import User from "./components/pages/settings/users/User";
import Category from "./components/pages/settings/category/Category";
import Content from "./components/pages/dashboard/Content";
import ContentConfig from "./components/pages/settings/contentConfig/ContentConfig";
import Quiz from "./components/pages/quiz/Quiz";

class App extends Component {

    render(){

        let name;
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Authentication}/>
                    <Route exact path="/dashboard" component={Dashboard}/>
                    <Route exact path={`/content/:${name}`} component={Content}/>
                    <Route exact path="/quiz" component={Quiz}/>
                    <Route exact path="/newsletter" component={Newsletter}/>
                    <Route exact path="/settings/user" component={User}/>
                    <Route exact path="/settings/category" component={Category}/>
                    <Route exact path="/settings/content" component={ContentConfig}/>
                    <Route path="/logout" render={() => {return <Redirect to={{pathname: "/"}}/> }}/>
                    <Route component={Error}/>
                </Switch>
            </BrowserRouter>
        );

    }

}

export default App;
