const toastService = {
    state: {background: '#14A2B8', icon: 'fa fa-2x fa-check', message: ''},

    switchDOM: (type: string) => {
        switch (type) {
            case 'SUCCESS':
                toastService.state.background = '#27A744';
                toastService.state.icon = 'fa fa-2x fa-check';
                break;
            case 'INFO':
                toastService.state.background = '#DEEDDE';
                toastService.state.icon = 'fa fa-2x fa-info-circle';
                break;
            case 'ERROR':
                toastService.state.background = '#DC3444';
                toastService.state.icon = 'fa fa-2x fa-warning';
                break;
            case 'WARNING':
                toastService.state.background = '#14A2B8';
                toastService.state.icon = 'fa fa-2x fa-warning';
                break;
            default :
                toastService.state.background = '#14A2B8';
                toastService.state.icon = 'fa fa-2x fa-check';
        }
    },

    showSuccess: (message) => {
        toastService.switchDOM('SUCCESS');
        toastService.state.message = message;

    },

    showError: (message) => {
        toastService.switchDOM('ERROR');
        toastService.state.message = message;

    },
};

export default toastService;
