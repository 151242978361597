import React, {Component} from 'react';
import Toast from "react-bootstrap/Toast";
import toastService from "./toastService";


class ToastRender extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            toastOpen: true
        };
        // this.onChange = this.onChange.bind(this);

    }


    toggle() {
        this.setState({
            toastOpen: !this.state.toastOpen
        });
    }

    render() {


        return (
            <div >
                <Toast show={this.state.toastOpen} onClose={this.toggle} style={{
                    position: 'absolute',
                    top: 0,
                    right: 25,
                    color: 'white'
                }}>
                    <Toast.Header style={{backgroundColor: toastService.state.background, borderBottom: 'none', color: 'white'}}>
                        <strong className="mr-auto"><i className={toastService.state.icon}/> Notification</strong>
                    </Toast.Header>
                    <Toast.Body style={{backgroundColor: toastService.state.background}}>See? Just like this. Bootstrap, See? Just like this.</Toast.Body>
                </Toast>
            </div>
        );

    }

}

export default ToastRender;
