import React, {Component} from 'react';
import './Newsletter.css';
import Header from "../../layouts/header/Header";
import Footer from "../../layouts/footer/Footer";
import {NavLink} from "react-router-dom";
import mailService from "../../../services/api_services/mailService";
import Form from "react-bootstrap/Form";

class Newsletter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allMails: []
        };
    }

    componentDidMount(): void {
        mailService.getAllMails((response) => {
            this.setState({allMails: response});
            console.log("all mails", this.state.allMails)
        });

    }

    // ToDO --- Toggle not working from BACKEND!!!! -- FIX!!!
    toggleStatus = (event, index) => {
        mailService.toggleMailStatus(index);
    };

    status = (value) => {
        if (value === false) {
            return 'Disabled'
        } else {
            return 'Enabled'
        }
    };


    render() {
        return (
            <div>
                <Header/>
                <section id="page-title">
                    <div className="container clearfix">
                        <h1>Newsletter</h1>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><NavLink to="#">Home</NavLink></li>
                            <li className="breadcrumb-item active" aria-current="page">Tables</li>
                        </ol>
                    </div>
                </section>
                <section id="content">

                    <div className="content-wrap">
                        <div className="container clearfix">

                            <h4>Scheduled Newsletters</h4>

                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Address</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>

                                {this.state.allMails.map((mail, index) =>
                                    <tr key={mail['_id']}>
                                        <td>{index + 1}</td>
                                        <td>{mail['address']}</td>
                                        <td>{String(this.status(mail['status']))}</td>
                                        <td>
                                            <Form.Check type="checkbox" id="custom-switch" label="Enable/Disable"
                                                        onChange={(e) => this.toggleStatus(e, mail['_id'])}
                                                        defaultChecked={mail.status}/>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        );

    }

}

export default Newsletter;
