import api_handler from "../api_handler";
import env from "../../environment";

const contentService = {

    getAllContents: (cb) => {
        const route = env.API_URL.prefix + env.API_URL.content_service.content;
        api_handler.getAPi(route, (res) => {
            cb(res.data);
        }, (err) => {
            console.log('error ', err);

        })
    },

    getAllContentsByCategory: (index, callback) => {
        const route = `${env.API_URL.prefix}${env.API_URL.content_service.contentByCategory}/${index}`;
        api_handler.getAPi(route, (res) => {
            callback(res.data);
        }, (err) => {
            console.log('error ', err);

        })
    },

    createNewContent: (data, callback) => {
        const route = env.API_URL.prefix + env.API_URL.content_service.content;
        api_handler.postApi(route, data, res => {
            console.log('added susccess', res); //ToDO Change to Toast
            callback();


        }, err => {
            console.log('error!!!! ', err);
        })
    },

    toggleContents: (index) => {
        const route = `${env.API_URL.prefix}${env.API_URL.content_service.toggleContent}/${index}`;
        api_handler.getAPi(route, res => {
            console.log('toggle successful', res); //ToDO Add Toast
        }, err => {
            console.log('toggle error', err); //ToDO Add Toast
        })

    }, //Todo Fix this service

    deleteContent: (index) => {
        const route = `${env.API_URL.prefix}${env.API_URL.content_service.content}/${index}`;
        api_handler.deleteApi(route, res => {
            console.log('deleted success', res); //TODO change to Toast
        }, err => {
            console.log('deleted error', err);

        })

    }


};

export default contentService;
