import axios from "axios";
import userService from "./userService";

const api_handler = {
    route: '',
    data: {},

    generalAPI: (method, success, error) => {
        axios[method](api_handler.route, api_handler.data)
            .then(res => {
                success(res.data);
            })
            .catch(err => {
                error(err.response);
            })
    },

    getAPi: (route, success, error) => {
        api_handler.route = route;
        api_handler.generalAPI('get', success, error);

    },

    postApi: (route, data, success, error) => {
        api_handler.route = route;
        api_handler.data = data;

        api_handler.generalAPI('post', success, error);
    },

    patchApi: (route, data, success, error) => {
        api_handler.route = route;
        api_handler.data = data;

        api_handler.generalAPI('patch', success, error);
    },

    putApi: (route, data, success, error) => {
        api_handler.route = route;
        api_handler.data = data;

        api_handler.generalAPI('put', success, error);
    },

    deleteApi: (route, success, error) => {
        api_handler.route = route;

        api_handler.generalAPI('delete', success, error);
    }

};

export default api_handler;

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    if (userService.isLoggedIn()) {
        config.headers = {Authorization: userService.isLoggedIn(), 'Content-Type': 'application/json'};

    } else {
        config.headers = {'Content-Type': 'application/json'};

    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

