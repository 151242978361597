import cacheService from "./cacheService";
import encryptService from "./encryptService";
import env from "../environment";
import api_handler from "./api_handler";

const userService = {

    isLoggedIn: () => {
        const TOKEN = cacheService.get('token');
        if (TOKEN) {
            return TOKEN
        } else {
            return false;
        }

    },

    userData: () => {
        const TOKEN = cacheService.get('token');
        return encryptService.jwtDecode(TOKEN);
    },

    userType: () => {
        const userGroup = userService.userData()['user_group'];
        if (userGroup === 'admin') {
            return 'Admin';
        } else {
            return 'General';
        }
    },

    getAllUsers: (cb) => {
        const route = env.API_URL.prefix + env.API_URL.users_service.user;
        api_handler.getAPi(route, (res) => {
            cb(res);
        }, (err) => {
            console.log('error ', err);
        })

    },

    createNewUser: (data, callback) => {
        const route = env.API_URL.prefix + env.API_URL.users_service.user;
        api_handler.postApi(route, data, res => {
            console.log('added success', res); //ToDO Change to Toast
            callback();

        }, err => {
            console.log('error!!!! ', err);
        })
    },

    toggleContents: (index) => {
        const route = `${env.API_URL.prefix}${env.API_URL.users_service.toggle}/${index}`;
        api_handler.getAPi(route, res => {
            console.log('toggle successful', res); //ToDO Add Toast
        }, err => {
            console.log('toggle error', err); //ToDO Add Toast
        })

    },

};

export default userService;
