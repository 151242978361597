
const magicClass = {

    createRender: false,

    pageContainerRender: {
        createRender: false,
        viewRender: false,
        listRender: true,
    },

    switchDOM: (type: string) => {
        switch (type) {
            case 'CREATE':
                magicClass.pageContainerRender.listRender = false;
                magicClass.pageContainerRender.viewRender = false;
                magicClass.pageContainerRender.createRender = true;
                break;
            case 'VIEW':
                magicClass.pageContainerRender.listRender = false;
                magicClass.pageContainerRender.createRender = false;
                magicClass.pageContainerRender.viewRender = true;
                break;
            case 'LIST':
                magicClass.pageContainerRender.createRender = false;
                magicClass.pageContainerRender.viewRender = false;
                magicClass.pageContainerRender.listRender = true;
                break;
            default :
                magicClass.pageContainerRender.createRender = false;
                magicClass.pageContainerRender.viewRender = false;
                magicClass.pageContainerRender.listRender = true;
        }
    },


};

export default magicClass;
