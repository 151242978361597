import React, {Component} from 'react';
import Header from "../../../layouts/header/Header";
import Footer from "../../../layouts/footer/Footer";
import {NavLink} from "react-router-dom";
import userService from "../../../../services/userService";
import Form from "react-bootstrap/Form";
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from "reactstrap";


class User extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            users: [],
            email: '',
            firstname: '',
            lastname: '',
            password: '',
            username: ''
        };


        // this.changePassword = this.changePassword.bind(this);
        this.usernameChange = this.usernameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.firstnameChange = this.firstnameChange.bind(this);
        this.lastnameChange = this.lastnameChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        this.toggleStatus = this.toggleStatus.bind(this);
        this.openModal = this.openModal.bind(this);

        this.submitUser = this.submitUser.bind(this);

    }

    componentDidMount(): void {

         userService.getAllUsers((data) => {
            this.setState({users: data.user});
            console.log('data', this.state.users);
        });
    }

    openModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    usernameChange(event) {
        this.setState({username: event.target.value});
    }

    emailChange(event) {
        this.setState({email: event.target.value});
    }

    lastnameChange(event) {
        this.setState({lastname: event.target.value});
    }

    firstnameChange(event) {
        this.setState({firstname: event.target.value});
    }

    passwordChange(event) {
        this.setState({password: event.target.value});
    }

    toggleStatus = (event, index) => {
        userService.toggleContents(index);
    };

    changePassword(){

    };

    submitUser(event) {
        event.preventDefault();
        const user = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            password: this.state.password,
            username: this.state.username,
            email: this.state.email,
            author: userService.userData()['firstname']
        };
        console.log('The user:: ', user);
        userService.createNewUser(user, cb => {
            this.setState({modal: false});
            window.location.reload();
        })
    }

    render() {
        return (
            <div>
                <Header/>
                <section id="page-title">
                    <div className="container clearfix">
                        <h1>User Accounts</h1>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><NavLink to="#">Home</NavLink></li>
                            <li className="breadcrumb-item active" aria-current="page">Users</li>
                        </ol>
                    </div>
                </section>
                <section id="content">
                    <div className="content-wrap">
                        <div className="container clearfix">
                            <h4>All Users</h4>
                            <div className="widget widget-twitter-feed">
                                <h4>Create New User</h4>
                                <div className="input-group mb-3">
                                    <button style={{width: '25%'}} type="button" className="btn btn-info" onClick={this.openModal}>New User &nbsp; <i className="fa fa-user-plus"/> </button>
                                </div>
                                <Modal isOpen={this.state.modal} toggle={this.openModal} className={this.props.className}>
                                    <ModalHeader toggle={this.toggle}>Create New User</ModalHeader>
                                    <ModalBody>
                                        <Form onSubmit={this.submitUser} encType="multipart/form-data">
                                            <FormGroup row>
                                                <Label for="username" sm={3}>Username</Label>
                                                <Col sm={9}>
                                                    <Input type="text" name="username" value={this.state.username} onChange={this.usernameChange} id="username" placeholder="Username" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="username" sm={3}>Email</Label>
                                                <Col sm={9}>
                                                    <Input type="email" name="email" value={this.state.email} onChange={this.emailChange} id="email" placeholder="Email" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="password" sm={3}>Password</Label>
                                                <Col sm={9}>
                                                    <Input type="password" name="password" value={this.state.password} onChange={this.passwordChange} id="password" placeholder="Password" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="firstname" sm={3}>Firstname</Label>
                                                <Col sm={9}>
                                                    <Input type="text" name="firstname" value={this.state.firstname} onChange={this.firstnameChange} id="firstname" placeholder="Firstname" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="lastname" sm={3}>Last Name</Label>
                                                <Col sm={9}>
                                                    <Input type="text" name="lastname" value={this.state.lastname} onChange={this.lastnameChange} id="lastname" placeholder="Last Name" />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup check row>
                                                <Col sm={{ size: 10, offset: 9 }}>
                                                    <Button type="submit" value="Submit">Submit</Button>
                                                </Col>
                                            </FormGroup>
                                        </Form>

                                    </ModalBody>
                                </Modal>
                            </div>


                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Username</th>
                                    <th>email</th>
                                    <th>User Type</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>

                                {this.state.users.map((user, index) =>


                                    <tr key={user['_id']}>
                                        <td>{index + 1}</td>
                                        <td>{user['firstname'] + ' ' + user['lastname']}</td>
                                        <td>{user['username']}</td>
                                        <td>{user['email']}</td>
                                        <td>{user['group_id']}</td>
                                        <td>
                                            <Form.Check type="checkbox" id="custom-switch" label="Enable/Disable"
                                                        onChange={(e) => this.toggleStatus(e, user['_id'])}
                                                        defaultChecked={user.status}/>
                                        </td>
                                        <td>
                                            <><i className="fa fa-2x fa-edit btn-outline-info"/></>
                                            <><i onClick={(e) => this.changePassword(e, user)} className="fa fa-2x fa-lock btn-outline-danger"/></>
                                        </td>
                                    </tr>
                                )}

                                </tbody>
                            </table>

                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        );

    }

}

export default User;
