import React, {Component} from 'react';
import './Dashboard.css';
import Header from "../../layouts/header/Header";
import Footer from "../../layouts/footer/Footer";
import categoryService from "../../../services/api_services/categoryService";
import userService from "../../../services/userService";

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            category: {}
        };
        this.categoryPage = this.categoryPage.bind(this);

    }

    componentDidMount(): void {
        categoryService.getAllCategories((data) => {
            this.setState({categories: data});
        });
    }

    categoryPage(event, category){
        this.props.history.push({pathname: `/content/${category['name']}`, state: {category: category}});
    }

    render() {

        if (userService.userType() === 'Admin') {

        }

        return (
            <div>
                <Header/>
                <section id="content">

                    <div className="content-wrap">

                        <div className="container clearfix">
                            <div className="row">
                            {this.state.categories.map((category, index) =>

                            <div key={category['_id']} className="col-4">
                                <div onClick={(event) => this.categoryPage(event, category)} className="point feature-box fbox-center fbox-bg fbox-border fbox-effect">
                                    <div className="fbox-icon">
                                        <div><i className="i-alt">{index + 1}</i></div>
                                    </div>
                                    <h3>{category['name']}<span className="subtitle">{category['description'] || 'Another Category'}</span></h3>
                                </div>
                            </div>
                            )}

                            <div className="col_last clear"/>
                            </div>
                        </div>
                    </div>
                </section>


                <Footer/>
            </div>
        );

    }

}

export default Dashboard;
