import React, {Component} from 'react';

import logo from '../../../assets/logo.png';
import {NavLink} from "react-router-dom";

class Error extends Component {

    render() {
        return (
            <div>
                <header id="header" className="full-header">
                    <div id="header-wrap">
                        <div className="container clearfix">
                            <div id="logo">
                                <a href="/" className="standard-logo"> <img src={logo} alt="JCC Logo"/></a>
                                <a href="/" className="retina-logo"><img src={logo} alt="JCC Logo"/></a>
                            </div>
                        </div>
                    </div>
                </header>
                <section id="content">
                    <div className="content-wrap">
                        <div className="container clearfix">
                            <div className="nobottommargin">
                                <div className="error404 center">404</div>
                                <h4 className="center">Ooopps.! The Page you were looking for, couldn't be found.</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <footer id="footer" className="dark footer-bg">

                    <div id="copyrights">
                        <div className="container clearfix">
                            <div className="col_half">
                                <NavLink to="/">JCC Dashboard</NavLink> | © 2019, All Rights Reserved. <br/>
                                <div className="copyright-links">
                                    Terms of Use | Privacy Policy
                                </div>
                            </div>
                            <div className="col_half col_last tright">
                                <div className="fright clearfix">
                                    <NavLink to="#" className="social-icon si-small si-borderless si-facebook">
                                        <i className="icon-facebook"/>
                                        <i className="icon-facebook"/>
                                    </NavLink>
                                    <NavLink to="#" className="social-icon si-small si-borderless si-twitter">
                                        <i className="icon-twitter"/>
                                        <i className="icon-twitter"/>
                                    </NavLink>
                                    <NavLink to="#" className="social-icon si-small si-borderless si-youtube">
                                        <i className="icon-youtube"/>
                                        <i className="icon-youtube"/>
                                    </NavLink>
                                    <NavLink to="#" className="social-icon si-small si-borderless si-podcast">
                                        <i className="icon-podcast"/>
                                        <i className="icon-podcast"/>
                                    </NavLink>
                                </div>
                                <div className="clear"/>
                                <i className="icon-envelope2"/> ollasmyke@gmail.com <span className="middot">·</span>
                                <i className="icon-headphones"/> +44-78-7385-4225 <span className="middot"/>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

        );
    }
}

export default Error;
