import React, {Component} from 'react';
import Header from "../../layouts/header/Header";
import Footer from "../../layouts/footer/Footer";
import {NavLink} from "react-router-dom";

class Quiz extends Component {


    render() {
        return (
            <div>
                <Header/>
                <section id="page-title">
                    <div className="container clearfix">
                        <h1>Quiz</h1>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><NavLink to="#">Home</NavLink></li>
                            <li className="breadcrumb-item active" aria-current="page">Quiz</li>
                        </ol>
                    </div>
                </section>
                <section id="content">

                    <div className="content-wrap">
                        <div className="container clearfix">

                            <h4>Working on it -- A Little Patience !!</h4>


                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        );

    }

}

export default Quiz;
