const env = {
    API_KEY: '',
    KEY: '12384-098757cc6jcc-987to890rcc67cc9-2976ted;C6Gp8UlpCYPJFdkAxfCyP4vml4bCLE19Yi8SCR3a5ye8QRlTC5ZQQxE1k3KJU',
    ADMIN_GROUP: '',
    PROJECT_ID:'jcc-dashboard',
    MONGO_URL: 'mongodb://localhost:27017/mystuff',
    LOCAL_URL: 'http://localhost:8004/apis/',
    PROD_URL: 'https://jcc-server.herokuapp.com/apis/',
    API_URL: {
        prefix: 'https://jcc-server.herokuapp.com/apis/',
        // prefix: 'http://localhost:8004/apis/',
        users_service: {user: 'users-service', toggle: 'users-service/toggleUser'},
        auth: {login: 'auth-service/login', logout: 'auth-service/logout'},
        category_service:  {category: 'content-service/category/', toggleCategory: 'content-service/category/toggle'},
        content_service:  {content: 'content-service/content', contentByCategory: 'content-service/content/category', toggleContent: 'content-service/content/toggle'},
        newsletter_service: {sendMail: 'newsletter-service/', noOfMails: 'newsletter-service/noOfMails', getMails: 'newsletter-service/', toggleMailStatus: 'newsletter-service/toggle'},


    }
};

export default env;
