import React, {Component} from 'react';
import Header from "../../layouts/header/Header";
import Footer from "../../layouts/footer/Footer";
import DummyImg from "../../../assets/images/dummy.png";
import {NavLink} from "react-router-dom";
import contentService from "../../../services/api_services/contentService";
import { Button, Modal, ModalHeader, ModalBody, Col, Form, FormGroup, Label, Input, FormText, Progress} from 'reactstrap';
import userService from "../../../services/userService";

import { storage } from '../../../services/firebaseConfig';



class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            category: this.props.location.state.category, //ToDO -- The reload issue is here !
            contents: [],
            name: '',
            description: '',
            message: '',
            image: '',
            url: '',
            progress: 0
        };
        this.status = this.status.bind(this);
        this.openModal = this.openModal.bind(this);

        this.nameChange = this.nameChange.bind(this);
        this.descriptionChange = this.descriptionChange.bind(this);
        this.messageChange = this.messageChange.bind(this);
        this.imageChange = this.imageChange.bind(this);

        this.handleUpload = this.handleUpload.bind(this);
        this.submitItem = this.submitItem.bind(this);

    }

    nameChange(event) {
        this.setState({name: event.target.value});
    }

    descriptionChange(event) {
        this.setState({description: event.target.value});
    }

    messageChange(event) {
        this.setState({message: event.target.value});
    }

    componentDidMount(): void {
        contentService.getAllContentsByCategory(this.state.category['_id'], data => {
            this.setState({contents: data});
        });
    }

    openModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    status = (value) => {
        if (value === false) {
            return 'Disabled'
        } else {
            return 'Enabled'
        }
    };

    imageChange = e => {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            this.setState(() => ({image}));
        }
    };

    handleUpload() {
        const {image} = this.state;

        if (this.state.image === '') {
            this.setState({url: ''})
        } else {
            const uploadTask = storage.ref(`images/${image.name}`).put(image);

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.setState({progress});
                },
                (error) => {
                    console.log(error);
                },
                () => {
                    storage.ref('images').child(image.name).getDownloadURL()
                        .then(url => {
                            console.log(url);
                            this.setState({url});
                        })
                }
            );

        }

    }

    submitItem(event) {
        event.preventDefault();
        const item = {
            name: this.state.name,
            description: this.state.description,
            message: this.state.message,
            category_id: this.state.category['_id'],
            image: this.state.url,
            author: userService.userData()['firstname'],
            userId: userService.userData()['id']
        };
        console.log('The item:: ', item);
        contentService.createNewContent(item, cb => {
            this.setState({modal: false});
            window.location.reload(); //ToDO improve with toast service, and no RELOAD!

        });
    }

    render() {

        return (
            <div>
                <Header/>
                <section id="page-title">
                    <div className="container clearfix">
                        <h1>{this.state.category.name}</h1>
                        <span>{this.state.category['description'] || ''}</span>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><NavLink to="/dashboard">Home</NavLink></li>
                            <li className="breadcrumb-item active" style={{textTransform: 'capitalize'}} aria-current="page">{this.state.category.name}</li>

                        </ol>
                    </div>
                </section>

                <section id="content">
                    <div className="content-wrap">
                        <div className="container clearfix">
                            <div className="widget widget-twitter-feed">
                                {/*ToDO -- add a go back icon.*/}
                                <div className="input-group mb-3">
                                    <button style={{width: '25%'}} type="button" className="btn btn-info"
                                            onClick={this.openModal}>Add New Content &nbsp; <i className="fa fa-object-group"/></button>
                                    <Modal isOpen={this.state.modal} toggle={this.openModal} className={this.props.className}>
                                        <ModalHeader toggle={this.toggle}>Create New Content</ModalHeader>
                                        <ModalBody>
                                            <Form onSubmit={this.submitItem} encType="multipart/form-data">
                                                <FormGroup row>
                                                    <Label for="name" sm={3}>Content Name</Label>
                                                    <Col sm={9}>
                                                        <Input type="text" name="name" value={this.state.name} onChange={this.nameChange} id="name" placeholder="Name of Content" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="description" sm={3}>Description</Label>
                                                    <Col sm={9}>
                                                        <Input type="textarea" name="description" value={this.state.description} onChange={this.descriptionChange} id="description" placeholder="Add a Little Description" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="message" sm={3}>Message</Label>
                                                    <Col sm={9}>
                                                        <Input type="textarea" name="message" value={this.state.message} onChange={this.messageChange} id="message" placeholder="Add the Message" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="image" sm={3}>Image</Label>
                                                    <Col sm={6}>
                                                        <Input type="file" name="image" onChange={this.imageChange} id="image" />
                                                        <FormText color="muted">
                                                            Only add an image file, either .jpg or .png format
                                                        </FormText>
                                                    </Col>
                                                    <Col sm={3}>
                                                        <Button onClick={this.handleUpload}>Upload</Button> <br/>
                                                        <Progress color="success" value={this.state.progress} max="100">{this.state.progress}</Progress>
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup check row>
                                                    <Col sm={{ size: 10, offset: 9 }}>
                                                        <Button type="submit" value="Submit">Submit</Button>
                                                    </Col>
                                                </FormGroup>
                                            </Form>

                                        </ModalBody>
                                    </Modal>
                                </div>
                            </div>
                            <hr />

                            <div id="posts" className="post-grid grid-container clearfix" data-layout="fitRows">

                                {this.state.contents.map((content, index) =>

                                <div key={content['_id']} className="entry clearfix">
                                    <div className="entry-image clearfix">
                                        <img alt={content['name']} width="100%" src={content['image'] || DummyImg}/>
                                    </div>
                                    <div className="entry-title">
                                        <h2><div>{content['name']}</div></h2>
                                    </div>
                                    <ul className="entry-meta clearfix">
                                        <li><i className="icon-calendar3"/> 28th Apr 2019</li>
                                        <li><NavLink to=""><i className="icon-user"/> {content['author']}</NavLink></li>
                                        <li><NavLink to="#"><i className="icon-settings"/>{String(this.status(content['status']))}</NavLink></li>
                                        <li><NavLink to="#"><i className="icon-edit"/>Edit</NavLink></li>
                                        <li><NavLink to="#"><i className="icon-trash"/>Delete</NavLink></li>
                                    </ul>
                                    <div className="entry-content">
                                        <p>{content['description'] || 'Lorem ipsum dolor sit amet elit. '}</p>
                                    </div>
                                </div>
                                )}

                            </div>
                        </div>
                    </div>

                </section>

                <Footer/>
            </div>

        );

    }
}

export default Content;
