import firebase from 'firebase/app';
import 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBeCziJBojXaOCKf2v2t2Ka1dkjayiUxBg",
    authDomain: "jccdashboard-9a3b8.firebaseapp.com",
    databaseURL: "https://jccdashboard-9a3b8.firebaseio.com",
    projectId: "jccdashboard-9a3b8",
    storageBucket: "jccdashboard-9a3b8.appspot.com",
    messagingSenderId: "145568570747",
    appId: "1:145568570747:web:24c2c60ad543e10dc5b1de",
    measurementId: "G-YYJTL0NV3E"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

const storage = firebase.storage();

export { storage, firebase as default }





